<template>
  <div>
    <h2>健康商城</h2>
  </div>
</template>

<script>
  export default {
    name: "Shop"
  }
</script>

<style scoped>

</style>
